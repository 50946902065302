/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $(window).resize(function() {
          $('.height-wrapper').css('min-height', $(window).height() - $('header').outerHeight() - $('footer').outerHeight());
        });

        $('#hamburger-button').click(function() {
          $(this).toggleClass('open').next('.nav-primary').slideToggle();
        });

        $('#signupform2 [name="are_you_an_existing_mysa_client"]').change(function(e) {
          if ($(this).val() == "yes") {
            $('#signupform2 .worker-name-field').show();
          } else {
            $('#signupform2 .worker-name-field').hide();
          }
        });
        $('#signupform2').submit(function(e) {
          e.preventDefault();
          $.ajax({
            type: 'POST',
            // dataType: 'json',
            url: ajax_url,
            data: $('#signupform2').serialize(),
            success: function(result) {
              if ($('body').hasClass('not-logged-in')) {
                alert('Thank you for register. Please login with your email and password.');
                $('#modalSignup').modal('hide').on('hidden.bs.modal', function() {
                  $('#modalLogin').modal('show');
                });
              } else {
                alert('Thank you for taking survey. Your Info has been updated.');
                $('#modalSignup').modal('hide');
              }
            }
          });
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        setTimeout(function() {$(window).resize(); }, 500);
        $('.form-control.date').datepicker();
        $.scrollUp();
        $('.matchHeight').matchHeight();

        if (location.search.substr(1) == "facebook=true") {
          $('#modalSignup').modal('show');
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'page_template_template_career_insights': {
      init: function() {
        $('.careers-list .show-more').click(function(e) {
          e.preventDefault();
          $(this).parent().prev().toggleClass('expand');
          return false;
        });
      }
    },

    'page_template_template_helpdesk': {
      init: function() {
        $('#modalUrgentAssist').modal('show');
        $('.modal > .vertical-alignment-helper').click(function(e) {
          if ($(e.target).is('.vertical-alignment-helper') || $(e.target).parent().is('.vertical-alignment-helper')) {
            $('#modalUrgentAssist').modal('hide');
          }
        });
      }
    },

    'single_resource': {
      init: function() {
        $('ul.nav li.menu-item-resources').addClass('current-menu-item');
      }
    },

    'single_course': {
      init: function() {
        $('ul.nav li.menu-item-courses').addClass('current-menu-item');
        $('.complete-button').click(function() {
          var $this_button = $(this);
          var id = $this_button.attr('disabled', 'disabled').attr('data-target');
          var status = $this_button.attr('data-complete');
          $.post(
            ajax_url,
            {action: 'complete_course', course_id: id, value: status}
          ).done(function(data) {
            location.href = location.href;
          });
        });
      }
    },

    'gated': {
      init: function() {
        $('#modalGate').modal({
          backdrop: 'static'
        });
        $('body').removeClass('modal-open').css('padding-right', '');
      }
    },

    'login_form': {
      init: function() {
        $('#modalLogin').modal('show');
      }
    },

    'register_form': {
      init: function() {
        $('#modalSignup').modal('show');
      }
    },

    'not_logged_in': {
      init: function() {
        $('.login-register-modal .forgot-link').click(function(e) {
          e.preventDefault();
          $('form#loginform').slideUp();
          $('form#lostpasswordform').slideDown();
          return false;
        });
        $('.login-register-modal .back-link').click(function(e) {
          e.preventDefault();
          $('form#loginform').slideDown();
          $('form#lostpasswordform').slideUp();
          return false;
        });
        $('.open-register-from-login').click(function(e) {
          e.preventDefault();
          $('#modalLogin').modal('hide').on('hidden.bs.modal', function() {
            $('#modalSignup').modal('show');
          });
          return false;
        });
        $('#signupform1').submit(function(e) {
          e.preventDefault();
          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_url,
            data: $('#signupform1').serialize(),
            success: function(result) {
              if (!result.success) {
                $('#signupform1 .errors').html(result.error_msg);
              } else {
                $('#signupform1 .errors').html('');
                $('#signupform1 .form-control').val('');
                $('#signupform1').slideUp();
                $('#signupform2').slideDown();
                $('#signupform2 input[name="user_id"]').val(result.success);
              }
            }
          });
          return false;
        });
      }
    },

    'has_search': {
      init: function() {
        $('#onpage-search-form').submit(function(e) {
          e.preventDefault();
          var search_key = $('#onpage-search-form #keyword').val().toLowerCase();
          $('.searchable').each(function() {
            var this_text = $(this).text().toLowerCase();
            if (this_text.search(search_key) < 0) {
              $(this).addClass('keyword-hide');
            } else {
              $(this).removeClass('keyword-hide');
            }
          });
          return false;
        });
        $('.search-bar .icon-tag').click(function(e) {
          e.preventDefault();
          $('.search-bar #tag-selecter').val($(this).attr('data-key')).change();
          return false;
        });
        $('.search-bar #tag-selecter').change(function() {
          var key = $(this).val();
          $('.searchable').removeClass('tag-hide').not(key).addClass('tag-hide');

          if (key === '*') {
            key = '.all';
          }
          $('.search-bar .icon-tag' + key).addClass('active').siblings('.icon-tag.active').removeClass('active');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
